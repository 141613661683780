// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// IE11 compat - uncomment if needed
// require("core-js/stable");
// require("regenerator-runtime/runtime");
// end IE11 compat

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

window.Rails = Rails
Rails.start()
ActiveStorage.start()

import LocalTime from "local-time"
LocalTime.start()
window.LocalTime = LocalTime

import "../src/js/pagy.js.erb"

import "../src/js/foundation-admin.js"
import "../src/js/unsaved_changes_warning.js"

import "../src/scss-admin/print.scss"
import "../src/scss-admin/app.scss"

import Alpine from "alpinejs"
window.Alpine = Alpine
Alpine.start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../src/img', true)
const imagePath = (name) => images(name, true)


import QrScanner from 'qr-scanner';
// QrScanner.WORKER_PATH = "/js/qr-scanner-worker.min.js";
import QrScannerWorkerPath from '!!file-loader!../../../node_modules/qr-scanner/qr-scanner-worker.min.js';
QrScanner.WORKER_PATH = QrScannerWorkerPath;

const initResult = () => {
  console.log("Starting scanner...");
  qrScanner.start();
  // videoEl.style.display = "none";

  var result = "Ready to scan.";
  if (window.location.protocol !== 'https:') {
    // warn but try starting the camera anyways
    result = "This page must be accessed via https to enable camera."
  }
  document.getElementById("result").innerHTML = result;
};

const redirectToURL = (url) => {
  qrScanner.pause();
  console.log(`Checking whether ${url} is a valid URL`)
  try {
    new URL(url);
    location.href = url;
  } catch (_) {
    // if not valid, start up the scanner again
    console.log(`URL not valid, resuming scanning`)
    setTimeout(initResult);
  }
};

const videoEl = document.querySelector("video#qrScanner");
if (videoEl) {
  window.qrScanner = new QrScanner(videoEl, result => redirectToURL(result), 640);
  initResult();
}
