import $ from "jquery"
import "what-input"
import "slick-carousel"

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.$ = $;
window.jQuery = $;



require('foundation-sites');
require('@fortawesome/fontawesome-free');

$(document).foundation();

$(document).ready(function(){
  $('.slick-slider').slick({
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow" aria-label="Previous" role="button" style="display: block;"><i class="fas fa-chevron-left"></i> Prev</button>',
    nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow" aria-label="Next" role="button" style="display: block;">Next <i class="fas fa-chevron-right"></i></button>'
  });
});

$(".building-panels .panel-card").click(function () {
  window.location = $(this).find("a").attr("href");
  return false;
});
$(".quick-grid .panel-nav-grid .item").click(function () {
  window.location = $(this).find("a").attr("href");
  return false;
});
$( ".toggle-menu" ).on("click", function() {  
  $( "aside.collapsable" ).toggleClass("collapsed-sidebar");
  if($( "aside.collapsable" ).hasClass("collapsed-sidebar")){    
    sessionStorage.setItem("collapsed-sidebar" , 1, 0);
  } 
  else{    
    sessionStorage.setItem("collapsed-sidebar" , 0, 0);
  }
});


  var isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
  if(isMobile == true ){
    // true for mobile device
    console.log("mobile device");
    if(sessionStorage.getItem("collapsed-sidebar") == ""){  
      $( "aside.collapsable" ).toggleClass("collapsed-sidebar");
      sessionStorage.setItem("collapsed-sidebar", 1, 0);
    }
    else{
      if(sessionStorage.getItem("collapsed-sidebar") == 1){    
        $( "aside.collapsable" ).toggleClass("collapsed-sidebar");
        sessionStorage.setItem("collapsed-sidebar", 1, 0);
      }  
    }
    $( "table.scroll" ).before( "<p class='table-note'><i class='far fa-hand-point-right'></i> drag table to scroll horizontally</p>" );
    $( "div.table-scroll" ).before( "<p class='table-note'><i class='far fa-hand-point-right'></i> drag table to scroll horizontally</p>" );

  }else{
    if(sessionStorage.getItem("collapsed-sidebar") == ""){  
      sessionStorage.setItem("collapsed-sidebar", 0, 0);
    }
    else{
      if(sessionStorage.getItem("collapsed-sidebar") == 1){    
        $( "aside.collapsable" ).toggleClass("collapsed-sidebar");
      }  
    }
  }

