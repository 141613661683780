import consumer from "./consumer"

window.connectTrespassChannel = (building_id) => {
  console.log("TrespassChannel: connecting to", building_id)

  consumer.subscriptions.create({ channel: "TrespassChannel", building_id: building_id }, {
    received(data) {
      // console.log("TrespassChannel: received", data)
      const event = new CustomEvent("trespass-received", { detail: data });
      window.dispatchEvent(event);
    }
  });
};
