window.unsavedChangesPresent = false

window.addEventListener("beforeunload", (e) => {
  if (window.unsavedChangesPresent) {
    // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    e.preventDefault()
    // Chrome requires returnValue to be set
    e.returnValue = ''
  }
})

document.querySelectorAll("form.warn-if-unsaved input, form.warn-if-unsaved select, form.warn-if-unsaved textarea").forEach((el) => {
  el.addEventListener("change", () => window.unsavedChangesPresent = true)
})
document.querySelectorAll("form.warn-if-unsaved").forEach((el) => {
  el.addEventListener("submit", () => window.unsavedChangesPresent = false)
})
