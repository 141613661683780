import consumer from "./consumer"

window.connectVisitChannel = (building_id) => {
  console.log("VisitChannel: connecting to", building_id)

  consumer.subscriptions.create({ channel: "VisitChannel", building_id: building_id }, {
    received(data) {
      // console.log("VisitChannel: received", data)
      const event = new CustomEvent("visit-received", { detail: data });
      window.dispatchEvent(event);
    }
  });
};
